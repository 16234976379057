import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/__core/services/app.service';
import { TranslationService } from 'src/app/__core/services/translation.service';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../auth.service';
import { MatSnackBarService } from 'src/app/__core/services/matSnackBar.service';
import { ChangeDetectionStrategy } from '@angular/compiler/src/compiler_facade_interface';
import { SharedService } from 'src/app/__shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ess-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [
    trigger('stagger', [
      transition('* => *', [
        query(':enter', stagger(60, animateChild()), { optional: true }),
      ])
    ]),
    trigger('fadeInUp', [
      transition(':enter', [
        style({
          transform: 'translateY(50px)',
          opacity: 0,
          
        }),
        animate(`700ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateY(0)',
          opacity: 1, 
        }))
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0)',
          opacity: 1
        }),
        animate(`300ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateX(50px)',
          opacity: 0
        }))
      ]),
    ])
  ],
})
export class ResetPasswordComponent implements OnInit {

  @Output() navigationTo = new EventEmitter();
  @Output() close = new EventEmitter();

  pageName: string = "login";
  baseHeaderImagePath: string = "assets/images/_header/";
  currentLanguage: any = this.lang.currentLanguage; 
  form: FormGroup
  currentStep: 'enterEmail' | 'enterVerificationCode' | 'enterNewPassword' = "enterEmail";
  verificationCode = new FormControl();
  newPassword = new FormControl();
  confirmPassword = new FormControl();
  
  imageCapcha: string = ""
  tokenCapcha: string = ""
  
  UserId: string;
  Token: string;

  constructor(
    public app: AppService, 
    private lang: TranslationService,
    private service: AuthService,
    private snack: MatSnackBarService,
    private sharedService: SharedService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      IdNumber: new FormControl('', Validators.compose([
        this.sharedService.checkValidityNumber,
        Validators.required
      ])),
      CaptchaString: new FormControl('', Validators.required),
    })
    this.GetCaptcha();
  }

  navigationPageTo = (pageName: any) => {
    this.navigationTo.emit(pageName)
  }

  closePopup = () => {
    this.close.emit(true);
  }
 


  forgetPassword = () => {
    if (this.form.invalid) { 
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      this.form.markAllAsTouched();
      return;
    }
    this.cd.markForCheck();
    let req = new FormData();
 
    req.append("IdNumber", this.form.get('IdNumber')?.value)
    req.append("CaptchaToken", this.tokenCapcha)
    req.append("CaptchaString", this.form.get('CaptchaString')?.value);
     
    this.service.forgetPassword(req).subscribe(res => { 
       
      if (res.errorCode == 0) {  
        this.Token = res.data.token
        this.currentStep = 'enterVerificationCode';  
        this.cd.markForCheck();
      } else { 
        this.snack.error(res.errorMessage);
        this.cd.markForCheck();
      }
    })
  }

  verify = () => {
    if (this.verificationCode.invalid) { 
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      this.form.markAllAsTouched();
      return;
    }
    let req = new FormData();
    
    
    req.append("Code", this.verificationCode.value);
    req.append("Token", this.Token); 

    this.service.verifyForgetPassword(req).subscribe(res => { 
       
      if (res.errorCode == 0) {  
        this.currentStep = 'enterNewPassword';
        this.cd.markForCheck();
      } else { 
        this.snack.error(res.errorMessage);
      }
    })
  }

  resetPassword = () => {
    if (this.newPassword.invalid && this.confirmPassword.invalid) { 
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      this.form.markAllAsTouched();
      return;
    }
    let req = new FormData();

    this.cd.markForCheck(); 
     
    req.append("Code", this.verificationCode.value);
    req.append("Token", this.Token);
    req.append("NewPassword", this.newPassword.value);
    req.append("ConfirmedPassword", this.confirmPassword.value);
    req.append("DeviceType", "3");

     

    this.service.resetPassword(req).subscribe(res => { 
       
      if (res.errorCode == 0) {  
        this.snack.success(this.lang.translate('yourPasswordIsReseted'));
        this.navigationTo.emit("login")
        this.cd.markForCheck();
      } else { 
        this.snack.error(res.errorMessage);
      }
    })
  }


  GetCaptcha = () => {
    this.sharedService.GetCaptcha().subscribe(res => {
      if (res.errorCode == 0) {
        this.imageCapcha = (environment.baseUrl + "File/" + res.data?.imagePath || "")
        this.tokenCapcha = res.data?.token || "";
        this.cd.detectChanges()
      } else {
        this.snack.error(res.errorMessage);
      }
    })
  }

}
