 
import { Observable, BehaviorSubject } from 'rxjs'; 
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router"; 
import { environment } from "src/environments/environment";
import jwt_decode from "jwt-decode";   
import { HttpClient } from '@angular/common/http';  
 

@Injectable({
    providedIn: "root"
})
export class AppService {
 

    //ATTRIBUTES 
    imageUrl: string = "";
    isLoading: boolean = false;
    isLoadingObs$: any
    imageToLightbox: any; 

    constructor( 
        private matSnackBar: MatSnackBar, 
        private route: ActivatedRoute, 
        private router: ActivatedRoute,
        private http: HttpClient
    ) {  }  

    isUserLoggedIn = (): boolean => localStorage.getItem(environment.appName) ? true : false

    // getUserData = (): any => localStorage.getItem(environment.appName + "UserData") ? JSON.parse(localStorage.getItem(environment.appName + "UserData") ?? "") : null ;

    showLoading = () => null;

    dismissLoading = () => null;

    getImage = (name: string) =>  name ? environment.baseUrl + "File/" + name : "assets/img/demo/default.jpg" 

    getTokenEncoding = (token: string) => jwt_decode(token);    

    convertSecondsToReadableString(startDate: any, endDate: any) {   
        var seconds = ( startDate.getTime() - endDate.getTime()) / 1000;

        seconds = seconds || 0;
        seconds = Number(seconds);
        seconds = Math.abs(seconds); 

        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);
        const parts = []; 
        (h > 0) &&parts.push(h + 'h');
        (m > 0) && parts.push(m + 'm');
        (s > 0) && parts.push(s + 's');
        return parts.join(', '); 
    }
 

    
    // getFileUploader(e) {
    //     if (e.target.files) {
    //       var reader = new FileReader();
    //       reader.readAsDataURL(e.target.files[0])
    //       this.imageFile = e.target.files[0]
    //       reader.onload = (event: any) => {
    //         this.imageUrl = event.target.result
    //       }
    //     }
    // } 

    //    ConfirmDialogMessage(deleteFunction: Function ) {
    //         let response = this.matDialog.open(ConfirmMessageComponent, { width: '500px' })
    //         response.afterClosed().subscribe((res: response) => { 
    //           res && Promise.resolve(deleteFunction()).then(() => {
    //                  this.showWarningMessage("Item Has Been Deleted")
    //               });  
    //         });
    //    }


}
 









