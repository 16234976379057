<mat-toolbar>
    <div class="container">
        <div class="navbar">
            <div class="left-side">
                <!-- <button class="mx-1" type="button" mat-button (click)="NavigationBar.toggle()">
                    <mat-icon>menu</mat-icon>
                </button> -->
                <img *ngIf="currentLanguage == 'en' " style="cursor: pointer;" [src]="headerImgPath + 'logoEnBlack.svg'" width="120px" [routerLink]="'/'">
                <img *ngIf="currentLanguage == 'ar' " style="cursor: pointer;" [src]="headerImgPath + 'logoArBlack.svg'" width="120px" [routerLink]="'/'">

                <div (click)="openExperimentalLaunchDialog()" class="experimental-launch">
                    <p> {{ 'ExperimentalLaunch' | translate }} </p>
                </div>
                
            </div>
            <div class="right-side">
                <button class="right-side_login-btn main-btn" *ngIf="isUserLoggedIn" routerLink="/profile" > 
                    {{ 'goProfile' | translate }} 
                </button>
                <button class="right-side_login-btn main-btn" *ngIf="!isUserLoggedIn" (click)="isCloased = false" > 
                    {{ 'login' | translate }} 
                </button>
                <span class="px-2"></span>
                <div class="language cursor-pointer" (click)="changeLanguage()" [dir]="currentLanguage != 'en' ?  'ltr' : 'rtl' " >
                    <img [src]="iconsImgPath + 'languageIcon.svg'">
                    <span> {{ currentLanguage != 'en' ? ( 'english' | translate ) : ( 'arabic' | translate ) }} </span>
                </div>
            </div>
        </div> 
    </div>
</mat-toolbar>

<section class="auth" *ngIf="!isCloased"  >
    <ess-auth @fadeInUp (close)="isCloased = $event" (isLoggedIn)="checkUserLoggedIn()" (click)="$event.stopPropagation" ></ess-auth>
</section>
