import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'src/app/__core/services/app.service';
import { TranslationService } from 'src/app/__core/services/translation.service';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'ess-experimental-launch-dialog',
  templateUrl: './experimental-launch-dialog.component.html',
  styleUrls: ['./experimental-launch-dialog.component.scss']
})
export class ExperimentalLaunchDialogComponent implements OnInit {
 
  headerImgPath: string = "assets/images/_header/";
  iconsImgPath: string = "assets/images/_icons/"; 
  currentLanguage: any = this.lang.currentLanguage;
  isCloased: boolean = true;
  isUserLoggedIn: boolean = false;
  
  constructor( 
    public app: AppService, 
    public lang: TranslationService, 
    public dialog: MatDialogRef<ExperimentalLaunchDialogComponent>, 
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

}
