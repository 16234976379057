<a mat-list-item 
  [ngStyle]="{'padding-left': (depth * 15) + 'px' }" 
  (click)="onItemSelected(item)"
  [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded }"
  matRipple
  class="menu-list-item">

  <mat-icon class="menu-item-icon"> {{ item.icon }} </mat-icon>
  <span class="menu-item-text"> {{ lang.translate(item.label) }} </span>
  <span fxflex class="twistie-separator"></span>


  <span *ngIf="item.children && item.children.length">
    <mat-icon class="menu-twistie" [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">expand_more</mat-icon>
  </span>
</a> 

<!-- Children -->
<div class="children" [class.active]="expanded" *ngIf="expanded">
  <ess-sidenav-items *ngFor="let child of item.children" [item]="child" [depth]="depth+1"></ess-sidenav-items>
</div>
