export const Arabic = {

    // Error 
    emailIsRequired: "عنوان البريد الإلكتروني غير كامل أو خاطئ",
    nameIsRequired: "الاسم مطلوب",
    passwordIsRequired: "كلمة السر مطلوبة",
    fullNameIsRequired: "الاسم الكامل مطلوب",
    mobileNumberIsRequired: "رقم الهاتف مطلوب",
    checkEmail: "تحقق من بريدك الإلكتروني",
    passwordMissMatch: "كلمة المرور غير متطابقة",


    // DIALOGS
    verifyHeaderDialog: "تحقق من حسابك الشخصي",
    verifyBodyDialog: "هل تريد التحقق من حسابك الشخصي؟؟",
    verify: "تحقق",
    cancel: "الغاء",


    // snackbarMessages
    welocme: "اهلا بك",
    usernameOrPasswordIsIncorrect: "الحساب الشخصي او كلمة السر خاطئه",
    somethingHasWentWrong: "حدث خطأ ما",
    EmailIsTaken: "الحساب مستخدم",
    userNotFound: "الحساب غير مستخدم",
    userNotVerifiedYet: "يجب تاكيد حسابك اولا",
    passwordChangedSuccessfully: "تم تعديل كلمة السر بنجاح",
    youMustLoginIn: "يجب عليك تسجيل الدخول",
    yourEmailCreatedSuccessfully: "تم تسجيل حسابك بنجاح",


    // HEADER
    allCities: "جميع الدول",
    favorite: "المفضلة",
    chats: "الدردشة",
    notifocations: "الاشعارات",
    loginOrSignUp: "تسجيل الدخول او تسجيل حساب",
    publishYourAd: "انشر اعلاناتك",


    // FOOTER
    company: "الشركاء",
    aboutUs: "من نحن",
    advertising: "اعلانات",
    careers: "المهن",
    termsOfUse: "الشروط و الاحكام",
    privacyPolicy: "سياسة الخصوصية",
    getSocial: "صفحاتنا الرسمية",
    facebook: "فيسبوك",
    twitter: "تويتر",
    youtube: "يوتيوب",
    instagram: "انستجرام",
    support: "الدعم الفني",
    help: "مركز الدعم",
    contactUs: "تواصل معنا",
    languages: "اللغات",
    english: "English",
    arabic: "العربية",
    downloadOurApp: "قم بتحميل التطبيق الأن",
    allRightsReserved: "كل الحقوق محفوظة",
    login: "تسجيل الدخول",
    comparePrices: " قارن الأسعار",
    secureYourCar: "وأمن مركبتك",
    andGetYourInsurancePolicyInMinutes: "واحصل على وثيقة مركبتك بكل سهولة",
    secondaryHeaderHomePageLine1: "أمني سيساعدك في عملية المقارنة لأكثر من 20 شركة ",
    secondaryHeaderHomePageLine2: "معتمدة لتأمين المركبات",
    aboutUsHeader: "أمنّي … يأمن سيارتك بكل سهولة",
    enterYourInformation: "سجل بياناتك",
    compareYourPrices: "واختار الوثيقة المناسبة لك",
    InsureYourCar: "أمن مركبتك",
    aboutUsBox1_text: "ادخل رقم الهوية / الإقامة ورقم لوحة السيارة ",
    aboutUsBox2_text: "قارن بين أسعار شركات التأمين واختر وخصص الوثيقة المناسبة إلك",
    aboutUsBox3_text: "ادفع واستلم تأمينك بشكل فوري وبسهولة",
    featureBox1_header: "أسعار موحدة", 
    featureBox2_header: "ربط مباشر مع نجم/المرور",
    featureBox3_header: "خطوات سهلة",
    featureBox4_header: "وثيقتك بطريقتك",
    featureBox1_text: "أسعارنا يا بتكون أقل أو مثل أسعار شركات التأمين، مستحيل تكون أغلى",
    featureBox2_text: "ربط فوري وإلكتروني مع نجم/المرور وتستطيع من خلاله تجديد الاستمارة في أبشر على مدار 24 ساعة",
    featureBox3_text: "في أقل من دقيقتين رح تقدر تختار وثيقتك وتأمن سيارتك",
    featureBox4_text: "بتقدر تختار وتفصل وثيقتك مثل ما بتحب وتضيف أي تغطية بتحتاجها",
    featureHead: "ما الذي يميز أمنّي؟",
    contactUsHeader: "كيف يمكنني مساعدتك؟",
    contactUsSubHeader: "يمكنك التواصل معنا", 
    instantChat: "محادثة فورية",
    commonQuestions: "الأسئلة الشائعة",
    andYouCanAlso: "و بتقدر كمان ",
    atAnyTime: "بأي",
    informationAboutUs: "معلومات عنا",
    carInsurance: "تأمين السيارة",
    newInsurance: "تأمين جديد",
    transferOfOwnership: "نقل ملكية",
    insuranceBodyHeader: "تأمين المركبات شامل /ضد الغير",
    showMeResult: "عروض الأسعار",
    errorIdNumber: "رقم الهوية المدخل غير صحيح، يرجى إعادة إدخال رقم الهوية الصحيح",
    idNumber: "رقم الهوية / الإقامة",
    effectiveDateDocument: "تاريخ بدء سريان الوثيقة",
    thisFieldIsReuired: "هذا الحقل مطلوب",
    aggreement: "أوافق على منح أمنّي الحق في الإستعلام من أي جهة ذات علاقة عن بياناتي أو أي بيانات أخرى",
    invalidCaptcha: "رمز التأكيد غير صحيح",
    drivers: "السائقين",
    vehicle: "المركبة",
    slaes: "العروض",
    checkout: "التفاصيل و الدفع",
    varifyText: "جميع معلوماتك بأمان معنا، يتم ارسالها فقط من خلال القنوات الحكومية والشركات التأمينية الرسمية",
    DocumentOwnerInformation: "معلومات مالك الوثيقة",
    id: "رقم الهوية",
    dateOfBirth: "تاريخ الميلاد (كما في الإقامة/الهوية)",
    year: "السنة",
    month: "الشهر",
    moreDetails: "+ تفاصيل اخرى",
    pleaseFillAllRequiredFields: "املئ الحقول المطلوبة من فضلك",
    somethingHasWentWronge: "حدث خطأ ما يرجى المحاولة في وقت اخر",
    otherDetailsForTheDocumentOwner: "تفاصيل أخرى لمالك الوثيقة",
    addDetails: "أضف التفاصيل",
    education: "التعليم",
    None: "لا شيئ",
    HighSchool: "المدرسة الثانوية",
    Diploma: "شهادة دبلوم",
    Bachelor: "بكالوريوس",
    Master: "ماستر",
    PhD: "دكتوراه",
    childrenUnder16: "عدد الأطفال دون عمر ال 16",
    driverAddress: "اسم و عنوان جهة العميل",
    accedant: "الحوادث",
    accedantDescription: "هل لدى السائق السائق أي حوادث سابقة؟",
    healthRestrictions: "القيود الصحية",
    healthRestrictionsDescription: "هل لدى السائق ظروف صحية أو قيود على الرخصة؟",
    trafficViolations: "مخالفات المرور",
    trafficViolationsDescription: "هل لدى السائق مخالفات مرورية؟",
    drivingLicenseFromOtherCountries: "رخصة قيادة من دول أخرى",
    drivingLicenseFromOtherCountriesDescription: "هل يمتلك السائق رخصة قيادة من دول أخرى؟",
    issuanceCountry: "دولة الاصدار",
    numberOfYearsHoldingTheLicense: "عدد سنوات إمتلاك الرخصة",
    add: "أضف",
    country: "الدولة",
    numberOfYears: "عدد السنوات",
    delete: "حذف",
    pleaseAddAtLeastCountry: "من فضلك اضف دولة واحدة على الاقل",
    formHasBeenAddedSuccessfully: "تم اضافة البيانات بنجاح",
    theCountryisAlreadyChoosen: "تم اضافة البلد من قبل",
    showOrEdit: "عرض/تعديل",
    ThereIsNo: "لا يوجد",
    moreThanTen: "اكثر من 10",
    moreThanFive: "اكثر من 5",
    years: "سنوات",
    contirmDeleteMessageTitle: "رسالة تأكيد",
    contirmDeleteMessageDesctioption: "هل انت متأكد من حذف هذا العنصر ؟",
    getDetails: "اظهار التفاصيل",
    name: "الاسم",
    doYouWantToAddOtherDriversToTheVehicle: "هل ترغب بإضافة سائقين أخرين للمركبة؟",
    extraDriverDescription: "إذا ما كنت متأكد، لا تقلق بتقدر تضيف سائقين بعدين",
    addDriver: "+ إضافة سائق",
    DriverIdNo: "رقم هوية السائق",
    DateOfBirth: "تاريخ الميلاد (كما في الإقامة/الهوية)",
    relativeRelation: "صلة القرابة",
    enterDriverId: "ادخل رقم هوية السائق",
    houseAddress: "عنوان المنزل",
    pledge: "أتعهد بصحة صلة قرابة السائق بمالك الوثيقة",
    sameOwnerFormerLocation: "نفس عنوان مالك الوثيقة",
    addedDriver: "سائق مضاف",
    dublicateRecorde: "السائق مضاف من قبل",
    carTitle: "نوع وثيقة المركبة",
    istimara: "الاستمارة",
    customsCard: "البطاقة الجمركية",
    serialNumber: "الرقم التسلسلي",
    vehicleSerialNumber: "الرقم التسلسلي للمركبة",
    chooseThePurposeOfUsingTheVehicle: "اختار الغرض من استخدام المركبة",
    modelYear: "تاريخ صنع السيارة",
    purposeOfUse: "الغرض من الاستخدام",
    getInfo: "عرض البيانات ",
    whereToParkTheVehicleAtNight: "مكان إيقاف المركبة في الليل",
    numberOfAccedant: "عدد الحوادث",
    expectedMiles: "عدد الكيلومترات المتوقع قطعها كل سنة",
    transmissionType: "نوع ناقل الحركة",
    normalGear: "الجير العادي",
    automaticGear: "جير اوتوماتيك",
    yes: "نعم",
    no: "لا",
    isEditOnVehicle: "هل يوجد تعديلات على المركبة",
    pleaseWriteTheModifications: "يرجى كتابة التعديلات ",
    numberOfTrafficsViolations: "عدد محالفات المرور",
    numberCustomsCard: "رقم البطاقة الجمركية",
    offer: "قائمة العروض",
    pricingTimeWillExpireIn: "سينقضي وقت التسعيرة خلال",
    hours: "ساعات",
    minutes: "دقيقة",
    seconds: "ثواني",
    chooseInsuranceType: "اختر نوع التأمين",
    tplOffer: "تأمين ضد الغير",
    cmpOffer: "تأمين شامل",
    pricesstartedFrom: "تبدا من 3499.00 ر.س",
    continue: "استمرار",
    back: "رجوع",
    youMustPledgeTheValidityOfTheRelationship: "يجب عليك التعهد بصحة صلة القرابة بك",
    updateDriver: "تعديل السائق",
    pleasAddYourInfo: "يرجى اضافة معلوماتك مع التفاصيل",
    pleasAddYourCarInfo: "يرجى اضافة معلومات سيارتك",
    carNotFound: "السيارة غير موجودة",
    chassisNumber: "الرقم الشاصي",
    cylinders: "عدد السلندرات",
    licenseExpiryDate: "تاريخ انتهاء الرخصة ",
    majorColor: "اللون",
    plateNumber: "رقم اللوحة",
    plateTypeDesc: "وصف رقم لوحة",
    sequenceNumber: "الرقم التسلسلي",
    vehicleMakerName: "اسم السيارة",
    vehicleModelName: "نوع السيارة",
    plateText1: "رقم اللوحة 1",
    plateText2: "رقم اللوحة 2",
    plateText3: "رقم اللوحة 3",
    vehicleLoad: "تحميل السيارة",
    vehicleWeight: "وزن السيارة",
    TheNumberExpectedToBeCutEachYear: "عدد الكيلومترات المتوقع قطعها كل سنة",
    CarDetails: "معلومات اخرى للمركبة",
    connectionSpead: "سرعة الربط",
    insuranceCoverage: "تغطية التأمين",
    additionalCovers: "تغطيات إضافية",
    ownerDocumentAndCarInformation: "معلومات مالك الوثيقة و المركبة",
    documentStartedDate: "تاريخ بدء الوثيقة",
    address: "العنوان",
    sortByPrice: "الترتيب حسب السعر",
    lowToHight: "من السعر الاقل الى الاعلى",
    heightToLow: "من السعر الاعلى الى الاقل",
    filter: "التصفية",
    seeMore: "عرض المزيد",
    seeLess: "اخفاء المزيد",
    monthMiladi1: " كانون الثاني",
    monthMiladi2: "شباط",
    monthMiladi3: "آذار",
    monthMiladi4: "نيسان",
    monthMiladi5: "أيار",
    monthMiladi6: "حزيران",
    monthMiladi7: "تموز",
    monthMiladi8: "آب",
    monthMiladi9: "أيلول",
    monthMiladi10: "تشرين الأول",
    monthMiladi11: "تشرين الثاني",
    monthMiladi12: "كانون الأول",
    monthHijri1: "محرم",
    monthHijri2: "صفر",
    monthHijri3: "ربيع الأول",
    monthHijri4: "ربيع الآخر",
    monthHijri5: "جمادى الأولى",
    monthHijri6: "جمادى الآخرة",
    monthHijri7: "رجب",
    monthHijri8: "شعبان",
    monthHijri9: "رمضان",
    monthHijri10: "شوال",
    monthHijri11: "ذو القعدة",
    monthHijri12: "ذو الحجة",
    summerDiscount: "خصم الصيف",
    discount: " 190.00 ر.س (10.00٪)",
    rs: "ر.س",
    termesAndConditon: "الشروط و الاحكام",
    choose: "اختيار",
    make: "صنع المركبة",
    vehicleInformation: "معلومات المركبة",
    vehicleColor: "لون المركبة",
    driversInformation: "بيانات السائقين",
    sharePrice: "شارك التسعيرة",
    youCanSharePriceAndPayLaterOn: "يمكنك مشاركة التسعيرة والدفع لاحقاً عن طريق الرابط التالي",
    copy: "نسخ الرابط",
    share: "مشاركة التسعيرة",
    documentPrice: "سعر الوثيقة",
    details: "التفاصيل",
    insuranceSubscriptionPremium: "قسط إشتراك التأمين",
    subTotal: "المجموع الجزئي",
    tax: "ضريبة اقيمة المضافة (15٪)",
    grandTotal: "المجموع الاجمالي",
    fixIn: "الاصلاح في",
    documentExpiryDate: "تاريخ انتهاء الوثيقة",
    EnduranceValue: "قيمة التحمل",
    vehicleCashValue: "قيمة المركبة النقدية",
    insuranceType: "نوع التأمين",
    insuranceWith: "تأمين مع",
    payment: "الدفع",
    iban: "رقم الحساب الدولي الخاص بمالك الوثيقة (IBAN)",
    enterIban: "ادخل رقم الحساب الدولي IBAN",
    IBANWillBeSavedToYourAccount: "سيتم حفظ الايبان الخاص بك داخل حسابك",
    documentOwnersEmail: "البريد الإلكتروني لمالك الوثيقة",
    documentOwnersMobileNumber: "رقم جوال مالك الوثيقة",
    enterValidEmail: "ادخل حساب صحيح",
    chooseWayOfPayment: "اختر طريقة الدفع",
    madaBankCard: "بطاقة مدى البنكية",
    masterCard: "ماستر كارد",
    visa: "فيزا",
    sada: "سداد",
    offerList: "العروض",
    iApproveOn: "اوافق على",
    PayNow: "أدفع الان",
    backToOffers: "الرجوع الى العروض",
    goProfile: "الدخول لحسابي",
    close: "أغلاق",
    signUp: "انشاء حساب",
    LogInToYourAccount: "تسجيل دخول لحسابك",
    email: "الريد الالكتروني",
    enterYourEmail: "يرجى كتابة البريد الالكتروني",
    enterYourPassword: "ادخل كلمة المرور",
    password: "كلمة المرور",
    forgetPassword: "هل نسيت كلمة المرور ؟",
    dontHaveAnAccount: "ليس لديك حساب",
    registerYourAccount: "؟تسجيل حساب جديد",
    createNewAccount: "انشاء حساب جديد",
    phoneNumber: "رقم الجوال",
    haveAnAccount: "لديك حساب؟",
    backToLogin: "العودة لتسجيل الدخول",
    restorePassword: "استعادة كلمة المرور",
    sendWerificationEmail: "رح نرسل رمز تحقق لبريدك الإلكتروني",
    verificationCode: "رمز التحقق",
    enterYourVerificationCode: "ادخل رمز التحقق",
    newPassword: "كلمة المرور الجديدة",
    enterYourNewPassword: "ادخل كلمة المرور الجديدة",
    confirmNewPassword: " تأكيد كلمة المرور الجديدة",
    loggedInSuccessfully: "تم تسجيل الدخول بنجاح",
    confirmPassword: "تأكيد كلمة السر",
    enterVerificationCode: "الرجاء إدخال رمز التحقق المرسل إلى هاتفك ",
    yourAccountIsVerifiedSuccessfully: "تم التحقق من حسابك بنجاح",
    send: "ارسال",
    yourPasswordIsReseted: "تم إعادة تعيين كلمة المرور الخاصة بك",
    aboutCompany: "عن الشركة",
    products: "المنتجات",
    insureCar: "تأمين السيارة",
    featureHeader: "ما الذي يميز أمني؟",
    vehicleValue: "قيمة المركبة",
    drivingRate: "نسبة القيادة",
    driverDeletedSuccessfully: "تم حذف السائق بنجاح",
    theSummationOfParcentageMustBeEqual100: "مجموع نسبة السياقة يجب ان تساوي 100",
    enduranceValue: "قيمة التحمل",
    pleaseLoginIn: "يجب عليك تسجيل الدخول",
    youHaveToPledgeToSaveThe: "يجب عليك التعهد بحفظ ال IBAN",
    paymentHasBeenDoneSuccessfullay: "عملية الدفع تمت بنجاح",
    comprehensiveInsuranceRequirements: "متطلبات التأمين الشامل",
    pleaseEnterRightIBAN: "يرجى ادخال رقم IBAN صحيح",
    frontSide: "الجهة الامامية",
    backSide: "الجهة الخلفية",
    rightSide: "الجهة اليمنى",
    leftSide: "الجهة اليسرى ",
    savePageAndGetBackanotherTime: "بعدك ما صورت السيارة؟ بسيطة احفظ الصفحة وكمل بعدين",
    savePage: "احفظ الصفحة",
    plageImages: "اقر بأن الصور المرفوعة هي الصور الحقيقة للمركبة المؤمن عليها، والتي تم اخذها مؤخرا ولم يتم عليها اي تعديل بأي شكل من الأشكال. واقر بمعرفتي بأن في حال اثبات عكس ذلك فإن هذا سيؤدي إلى الغاء وثيقة التأمين وسيؤثر على المطالبات المرفوعة على المركبة.",
    congratulationsInsurance: "مبارك التأمين",
    insuranceHasBeenPurchasedSuccessfully: "تمت عملية شراء وثيقة التأمين بنجاح",
    voucherNumber: "رقم الفاتورة",
    voucherDetails: "تفاصيل الفاتورة",
    Ammeni: "أمني",
    billerName: "اسم المفوتر",
    billerNumber: "رقم المفوتر",
    totalDocumentPrice: "قيمة الوثيقة",
    documentOwner: "مالك الوثيقة",
    pricingReferenceNumber: "الرقم المرجعي للتسعيرة",
    weSendMessageToU: "سوف نقوم بإرسال رسالة نصية لرقم الهاتف الخاص بك فور إصدار الوثيقة ",
    goToMyProfile: "الذهاب لملفي الشخصي",
    ImageUploadedSuccessfully: "تم تحميل الصورة بنجاح",
    payNewInsurance: "شراء تأمين جديد",
    myProfile: "حسابي",
    myVehicles: "مركباتي",
    insuranceDocuments: "وثائق التأمين",
    settings: "الاعدادات",
    welcome: "مرحبا بك",
    registerdInentities: "الهويات المسجلة",
    registerdInentitiesDescription: "سوف يتم إظهار هذه الهويات في قائمة الإختيار عند أي عملية شراء جديدة",
    theDocumentOwnersIdNumber: "رقم هوية مالك الوثيقة",
    buyItBeforeItGone: "اشتريها قبل ما تروح عليك",
    completePurchaseProcess: "اكمل عملية الشراء",
    recoveryDocumentInsurance: "استرجاع وثيقة تأمين",
    validDocuments: "وثائق سارية المفعول",
    nearingExpiryDocuments: "وثائق قاربت على الإنتهاء",
    expiredDocuments: "وثائق منتهية الصلاحية",
    renewedDocuments: "وثائق تم تجديدها",
    invoices: "الفواتير",
    type: "نوع السيارة",
    owner: "المالك",
    description: "التفاصيل",
    status: "الحالة",
    showDocumentDetails: " عرض تفاصيل الوثيقة",
    thereIsNoAnyVehiclesRegisterdYet: "لا توجد أي مركبات مسجلة حتى الآن",
    vehicleDeatils: "تفاصيل المركبة",
    idAndIqamma: "رقم الهوية / الاقامة",
    moreInfo: "تفاصيل اخرى",
    show: "عرض",
    deleteVehicle: "حذف المركبة",
    thereIsNoValidInsuranceDocuments: "لا توجد وثائق تأمين سارية المفعول",
    authorizedBy: "مصرح من قبل",
    saudiCentralBank: "البنك المركزي السعودي",
    driverDeletedSuccessfuly: "تم حذف السائق بنجاح",
    expirtDate: "تاريخ الانتهاء",
    cardNumber: "رقم البطاقة",
    cardName: "الاسم على البطاقة",
    enterCardInformation: "ادخل معلومات البطاقة",
    youMustPladgeOnTermsOfUse: "يجب عليك التعهد على الشروط و الاحكام ",
    youMustPladgeOnCarImages: "يجب عليك التعهد على صور المركبة",
    youMustChoosePaymentMethod: "يجب عليك اختيار طريقة دفع",
    youMustPadgeOnSavingIBANAccount: "يجب عليك التعهد بحفظ IBAN الخاص بك ",
    thereAreNoValidInsuranceDocuments: "لا يوجد   ",
    Pending: "في الانتظار",
    QUOTE: "مقتبس",
    DRAFT: "مسودة",
    ISSUED: "صادر",
    Cancelled: "ملغى",
    Expired: "منتهي الصلاحية",
    monthValueIsInvalid: "يرجة ادخال شهر صحيح",
    yearValueIsInvalid: "يرجى ادخال سنة صحيحة",
    thereIsNoOfffers: "لا يوجد عروض ",
    PendingOrders: "الطلبات العالقة",
    insuranceCompany: "شركة التأمين",
    Price: "السعر",
    ExpiryDate: "صالح لغاية",
    View: "عرض",
    policyDetails: "تفاصيل الوثيقة",
    print: "طباعة",
    additionalCoveragesPrice: "التغطيات الاساسية",
    total: "المجموع",
    administrativeExpenses: "المصاريف الادارية",
    iqamaNumber: "رقم الاقامة",
    mobileNumber: "رقم الهاتف",
    birthDate: "تاريخ الميلاد بالميلادي",
    birthDateHijri: "تاريخ الميلاد بالهجري",
    DocumentOwnerAndVehicleInformation: "معلومات مالك الوثيقة و المركبة",
    repairCondition: "الإصلاح في",
    sumInsured: "قيمة المركبة النقدية",
    Cancel: "إلغاء",
    AllOffers: "كل العروض",
    ExpiredOfferNote: "إنتهت فترة عرض السعر.",
    NoAdditionsNote: "لا يوجد تغطيات إضافية متوفرة في هذا العرض",
    NoOffersFound: "لا يتوفر أي عرض حاليا, الرجاء المحاولة فيما بعد.",
    InsuranceCompany: "شركة التأمين",
    ConfirmAction: "التأكيد",
    ProposalInfo: "معلومات عرض السعر",
    SelectOfferConfirmation: "نعم, إختر هذا العرض",
    NcdDiscount: "خصم عدم وجود مطالبات",
    LoyalityDiscount: "خصم الولاء",
    PickThisOffer: "إختر هذا العرض؟",
    NetPrice: "السعر النهائي",
    OfferedBy: "مقدم من",
    Subject: "الموضوع",
    Name: "الإسم",
    Mobile: "الجوال",
    Email: "البريد الإلكتروني",
    Details: "التفاصيل",
    Submit: "إرسال",
    contactUsParagraph: "يمكنك التواصل معنا من خلال الواتساب عن طريق هذا الرقم. من الأحد إلى الخميس من الساعة ٨ صباحاً حتى الساعة ١١ مساءً",
    WhatsApp: "الواتساب",
    CustomerService: "خدمة العملاء",
    CustomerServiceParagraph: "يمكنك التواصل معنا من خلال رقم خدمة العملاء. من الأحد إلى الخميس من الساعة ٨ صباحاً حتى الساعة ٥ مساءً",
    emailParagraph: "لأنك قد تحتاج إلى المساعدة في أي وقت ، لذا أرسل بريدًا إلكترونيًا وسنتواصل معك في أقرب وقت ممكن    ",
    ContactUsThankYouMessage: "شكرا لك لتواصلك معنا, سيتم مراجعة رسالتك في اقرب وقت",
    MessageSent: "تم إرسال الرسالة",
    YourMessageGasBeenSent: "تم إرسال رسالتك بنجاح",
    OurTeamReviewMessage: "فريقنا سيقوم بمعاينة الرسالة والتواصل معك بأسرع وقت",
    SupportCenterWelcome: "حياك ، كيف نقدر نساعدك؟",
    faqSearchBoxPlaceHolder: "ابحث عن اي شيء مثل التسعيرة، الدفع … إلخ",
    logout: "تسجيل خروج",
    NeedKnowMore: "شو بتحب تعرف كمان؟",
    WeHaveAnswers: "شاركنا استفسارك",
    CanContactUsAnyTime_A: "يمكنك",
    CanContactUsAnyTime_B: "التواصل معنا",
    CanContactUsAnyTime_C: "بأي وقت",
    PersonalInfo: "المعلومات الشخصية",
    Notifications: "الإشعارات",
    currentPassword: "كلمة المرور الحالية",
    Save: "حفظ",
    Language: "اللغة",
    DataSaved: "تم حفظ البيانات بنجاح.",
    Change: "تغيير",
    CurrentMobile: "رقم الجوال الحالي",
    NewMobileNumber: "رقم الجوال الجديد",
    SendVerificationCode: "إرسال رمز التحقق",
    VerificationCode: "رمز التحقق",
    codeSentToMobile: "تم إرسال رمز التحقق الى هاتفك الجوال, الرجاء ادخاله في الخانة المخصصة لذلك",
    offerType: "نوع التأمين",
    View_All_Offers: "إستعراض كل العروض",
    TPL_Offers: "تأمين ضد الغير",
    COMP_Offers: "تأمين شامل",
    provideVehicleValue: "الرجاء إضافة قيمة المركبة",
    newMobileIsRequired: "رقم الجوال الجديد إجباري",
    verificationCodeIsRequired: "رمز التحقق إجباري",
    MobileChangedSuccessfully: "تم تغيير رقم الجوال بنجاح",
    EmailChangedSuccessfully: "تم تغيير البريد الالكتروني بنجاح",
    CurrentEmail: "البريد الإلكتروني الحالي",
    NewEmail: "البريد الإلكتروني الجديد",
    codeSentToEmail: "تم إرسال رمز التحقق الى البريد الإلكتروني الجديد, الرجاء ادخاله في الخانة المخصصة لذلك",
    newOwner: "المالك الجديد",
    currentOwner: "المالك الحالي",
    PleaseFillNewOwnerId: "الرجاء ادخال رقم الهوية أو الإقامة للمالك الجديد",
    PleaseFillCurrentOwnerId: "الرجاء ادخال رقم هوية / إقامة المالك الحالي",
    payMoreToGetComp: "يمكنك الحصول على تأمين شامل بزيادة مبلغ",
    clickToViewCmp: "إضغط هنا لرؤية العرض الشامل",
    receiveNotifications: "إستقبال الاشعارات",
    beforeTheDocumentExpires: "قبل انتهاء الوثيقة",
    whenTheDocumentExpires: "عند إنتهاء الوثيقة",
    days: "يوم",
    notificationSettingsSavedSuccessfully: "تم حفظ اعدادات الاشعارات",
    medicalInsurance: "تأمين الاخطاء الطبية",
    travelInsurance: "تأمين السفر",
    perpuseOfTravel: "الغرض من الرحلة",
    typeOfTravel: "نوع الرحلة",
    destination: "وجهة الرحلة",
    yourStatus: "حالتك",
    TransferOfOwnership: "نقل ملكية",
    TrvelInformation: "معلومات الرحلة",
    travelDate: "تاريخ السفر",
    returnDate: "تاريخ العودة",
    countries: "الدول",
    addTraveler: "اضافة المسافر",
    tripAndTravelersInfo: "معلومات السفر المسافرين",
    pleasAddTripInfo: "يرجى اضافة معلومات السفر و المسافرين ",
    pleasTravelerAtLeast: "يرجى اضافة مسافر على الاقل",
    trpInfo: "معلومات السفر",
    travelPerpuse: "غرض السفر",
    travelType: "نوع الرحلة",
    travelDistenation: "وجهة السفر",
    travelStartingDate: "تاريخ بدء السفر",
    travelEndingDate: "تاريخ انتهاء السفر",
    typeOfTravelHint: "إذا كنت تخطط للسفر لنفس الوجهة عدة مرات خلال الفترة القادمة فهذا الإختيار سيقدم لأفضل تأمين سفر مناسب لك",
    documentOwnerInfo: "بيانات مالك الوثيقة",
    documentAndMedicalSpecialty: "الوثيقة و التخصص الطبي",
    StatementsOfTheDocumentOwner: "تصريحات مالك الوثيقة",
    doYouPerformSurgeriesOrParticipateInSurgeries: "هل تقوم بعمل عمليات جراحية أو تشارك في العمليات الجراحية؟",
    documentStartingDate: "تاريخ بدء الوثيقة ",
    numbersOfExperienceYears: "عدد سنوات الخبرة",
    rejoinPracticeWork: "منطقة ممارسة العمل",
    rejoinWork: "مكان العمل ",
    medicalSpecialty: "التخصص الطبي",
    doYouPerformSurgeries: "هل تقوم بعمل عمليات جراحية أو تشارك في العمليات الجراحية؟ ",
    ExperimentalLaunch: "إطلاق تجريبي",
    medicalStatemetsQ1: "هل تملك وثيقة تأمين ضد الأخطاء الطبية سارية أو منتهية؟",
    medicalStatemetsQ2: "هل توجد أي مطالبات أو قضايا مرفوعة ضدك بسبب الإهمال أو الخطأ في خلال فترة العشر سنوات الماضية؟",
    medicalStatemetsQ3: "هل أنت على علم بأي مطالبات أو قضايا مرفوعة ضد أياً من شركائك ، أو المساعدين الشخصيين، أو الممرضات أو الفنيين، الذين تم تعيينهم من قِبَلك بسبب الإهمال أو الخطأ في خلال فترة العشر سنوات الماضية؟",
    medicalStatemetsQ4: "هل حدث من قبل أن أحد شركات التأمين رفضت أو ألغت أو قبلت تحت شروط معينة طلبك للتأمين ضد الأخطاء الطبية سواء كان طلب جديد أو طلب تجديد؟",
    medicalStatemetsQ5: "هل أنت على علم بأي أحداث أو ظروف حالية قد تؤدي إلى رفع مطالبات أو قضايا ضدك في المستقبل؟",
    medicalStatemetsQ6: "هل سبق أن تم إدانتك بمخالفة أو إنتهاك القانون (بجانب المخالفات المرورية) أو خضعت لإجراءات تأديبية أو توبيخ من قبل أي وكالة إدارية تابعة لنقابة مهنية؟",
    medicalStatemetsQ7: "هل انتهيت بنجاح من مرحلة إعادة التأهيل الجنائي مع الجهات المختصة؟",
    cancellationReason: "سبب الإلغاء", 
    cancelRequest: "طلب الغاء الوثيقة",
    uploadFileRejection: "ارفق ملف الالغاء",
    pleaseUploadFileRejection: "يرجى يرفع ملف الالغاء",
    requestDeleteUnderProccessing: "طلب الالغاء تحت المعالجة",
    insuranceAuthority: "هيئة التأمين",
    pleaseWait: "يرجى الانتظار"
};
