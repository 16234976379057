
<mat-drawer-container class="example-container" autosize> 
    <mat-drawer [mode]="'over'" #drawer class="example-sidenav" >
        <div class="config">
            <span class="config__logo">
                <!-- <img [src]="config?.logo" width="75%" height="75%"> -->
            </span>
            <span class="config__name">
            </span>
        </div> 

        <div class="category px-3 mt-4">
            <p>pages</p>
        </div> 
 
        <mat-nav-list>
            <ess-sidenav-items *ngFor="let item of menus" [item]="item"></ess-sidenav-items>
        </mat-nav-list>
    </mat-drawer> 
 
    <div class="example-sidenav-content">
        <ess-header [NavigationBar]="drawer"></ess-header> 
    </div> 

    
    
    <main> 
        <div *ngIf="appService.isLoadingObs$ | async" class="loading"  >
            <mat-spinner color="primary"></mat-spinner>
        </div>
        <router-outlet></router-outlet> 
    </main>
    
    
</mat-drawer-container>