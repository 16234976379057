 
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Component, OnInit, HostBinding, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NavService } from './nav.service';
import { Router } from '@angular/router';
import { NavItem } from 'src/app/__core/interfaces/navigationItems';
import { AppService } from 'src/app/__core/services/app.service';
import { TranslationService } from 'src/app/__core/services/translation.service';

@Component({
  selector: 'ess-sidenav-items',
  templateUrl: './sidenav-items.component.html',
  styleUrls: ['./sidenav-items.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemsComponent implements OnInit {
  expanded: boolean = false;

  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input('item') item!: NavItem;
  @Input('depth') depth!: number;

  constructor(
    public navService: NavService,
    public router: Router,
    private cd: ChangeDetectorRef,
    public lang: TranslationService,
  ) { }


  ngOnInit() {
    this.cd.markForCheck()
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        this.cd.markForCheck()
      }
    });

    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  onItemSelected(item: NavItem) {
    this.cd.markForCheck()

    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      this.cd.markForCheck()
    }

    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
      this.cd.markForCheck();
    }
  }
}
