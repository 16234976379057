<div class="container actions">
    <button (click)="navigationPageTo('login')" class="active"> {{ 'login' | translate }} </button>
    <button (click)="navigationPageTo('signUp')" > {{ 'signUp' | translate }} </button>
</div>
<section @fadeInUp class="auth-control">
    <div class="close cursor-pointer" (click)="closePopup()">
        {{ 'close' | translate }}
    </div>

    <div class="custom-container">
        <div class="auth-control-header">
            <img *ngIf="currentLanguage == 'ar'" [src]="baseHeaderImagePath + 'logoArBlack.svg'" width="150px" alt="">
            <img *ngIf="currentLanguage == 'en'" [src]="baseHeaderImagePath + 'logoEnBlack.svg'" width="150px" alt="">
        </div>
    </div>

    <div class="auth-control-body mt-3">
        <div class="custom-container">
            <p class="my-3 f-bolder"> {{ 'restorePassword' | translate }} </p>
            <p class="my-3 f-bolder" *ngIf="currentStep == 'enterEmail' " > {{ 'sendWerificationEmail' | translate }} </p>
 


            <!-- StepNumberOne -->
            <div class="row" [formGroup]="form" *ngIf="currentStep == 'enterEmail' " > 
                <div class="col-md-12 my-2">
                    <mat-label class="f-bolder"> {{ 'idNumber' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input maxlength="10" (keyup.enter)="forgetPassword()" type="text" matInput formControlName="IdNumber" [placeholder]=" 'idNumber' | translate " >
                        <mat-error>
                            <span *ngIf="form.get('IdNumber')?.value"> {{ 'errorIdNumber' | translate }} </span>
                            <span *ngIf="form.get('IdNumber')?.hasError('required')"> {{ 'thisFieldIsReuired' | translate }} </span>
                        </mat-error>
                    </mat-form-field>  
                </div>  

                <!-- CAPCHAA -->
                <div class="col-sm-12 capchaa-cont">
                    <mat-form-field appearance="outline">
                        <img style="display: flex; align-items: center; margin-top: 9px; height: 100%;" matPrefix [src]="imageCapcha">
                        <img style="display: flex; align-items: center; margin-top: 9px; height: 100%;" matSuffix [src]="'assets/images/_home/resenCodeIcon.svg'" (click)="GetCaptcha()">
                        <input maxlength="7" (keyup.enter)="forgetPassword()" style="font-size: 20px !important; padding: 0 20px;"
                            autocomplete="off" matInput type="text" formControlName="CaptchaString">
                        <mat-error> {{ 'thisFieldIsReuired' | translate }} </mat-error>
                    </mat-form-field>
                </div>
                
                
                <div class="col-md-12">
                    <button (click)="forgetPassword()" class="main-btn w-100"> {{ 'send' | translate }} </button>
                    <div class="text-center my-3">
                        <span class="text-gray"> {{ 'dontHaveAnAccount' | translate }} </span> 
                        <span class="text-blue cursor-pointer" (click)="navigationPageTo('signUp')"> {{ 'registerYourAccount' | translate }} </span>
                    </div>  
                    <div class="text-center my-3 text-blue cursor-pointer" (click)="navigationPageTo('login')" >
                        {{ 'backToLogin' | translate }}
                    </div>
                </div>  
            </div> 







            <!-- StepNumberTwo -->
            <div class="row" @fadeInUp *ngIf="currentStep == 'enterVerificationCode' " >  
                <div class="col-md-12 my-2">
                    <mat-label class="f-bolder"> {{ 'verificationCode' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input (keyup.enter)="verify()" type="text" matInput [formControl]="verificationCode" [placeholder]=" 'enterYourVerificationCode' | translate " >
                        <mat-error> 
                            <p> {{ 'thisFieldIsReuired' | translate }}  </p> 
                        </mat-error>
                    </mat-form-field>
                </div>  
                <div class="col-md-12">
                    <button (click)="verify()" class="main-btn w-100"> {{ 'verify' | translate }} </button> 
                    <div class="text-center my-3 text-blue cursor-pointer" (click)="navigationPageTo('login')" >
                        {{ 'backToLogin' | translate }}
                    </div>
                </div>  
            </div>




            <!-- StepNumberThree -->
            <div class="row" @fadeInUp [formGroup]="form" *ngIf="currentStep == 'enterNewPassword' " >  
                <div class="col-md-12 my-2">
                    <mat-label class="f-bolder"> {{ 'newPassword' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input type="password" matInput [formControl]="newPassword" [placeholder]=" 'enterYourNewPassword' | translate " >
                        <mat-error> 
                            <p> {{ 'thisFieldIsReuired' | translate }}  </p> 
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-12 my-2">
                    <mat-label class="f-bolder"> {{ 'confirmNewPassword' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input (keyup.enter)="resetPassword()" type="password" matInput [formControl]="confirmPassword" [placeholder]=" 'confirmNewPassword' | translate " >
                        <mat-error> 
                            <p> {{ 'thisFieldIsReuired' | translate }}  </p> 
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <button (click)="resetPassword()" class="main-btn w-100"> {{ 'login' | translate }} </button>
                    <div class="text-center my-3">
                        <span class="text-gray"> {{ 'dontHaveAnAccount' | translate }} </span> 
                        <span class="text-blue cursor-pointer" (click)="navigationPageTo('signUp')"> {{ 'registerYourAccount' | translate }} </span>
                    </div>  
                    <div class="text-center my-3 text-blue cursor-pointer" (click)="navigationPageTo('login')" >
                        {{ 'backToLogin' | translate }}
                    </div>
                </div>  
            </div>
            
            

            
            
        </div> 
    </div> 
</section> 