
import { environment as env } from "src/environments/environment";
const base = env.baseUrl;

export const endPoints = {
    base: base,
    // Home 
    home: base + "Home",
    homeIds: base + "Home/MyRegisteredIds",
    getInsuranceCompaniesImage: base + "InsuranceCompanies/image/",
    getCapchaImage: base + "Captcha/get-captcha-image/",
    PurchaseOrders: base + "PurchaseOrders/request",
    GetDriver: base + "Drivers/Get",
    deleteExtraDriver: base + "Drivers/DeleteExtraDriver/",
    gethealthConditionsLookups: base + "Lookups/health-conditions",
    getclaimTypesLookups: base + "Lookups/claim-types",
    getCountries: base + "Lookups/countries", 
    getRelationships: base + "Lookups/relationships", 
    getVehiclePurposes: base + "Lookups/vehicle-purposes",
    getVehicleParking: base + "Lookups/vehicle-parking",
    VehicleMiles: base + "Lookups/expected-miles",
    vehicleByCustom: base + "Vehicles/vehicle-by-custom",
    vehicleBySequence: base + "Vehicles/vehicle-by-sequence",
    vehicleByRequest: base + "Vehicles/vehicle-by-request/",
    save: base + "PurchaseOrders/save",
    getOffers: base + "PurchaseOrders/get-proposal/",
    getProposals: base + "PurchaseOrders/get-proposals/",
    expiryChecker: base + "PurchaseOrders/expiry-check/",
    selectOffer: base + "PurchaseOrders/select-proposal",
    login: base + 'Authenticate/login',
    signUp: base + 'Registration/register',
    verifyRegister: base + 'Registration/verify',
    resendVerifyRegister: base + 'Registration/resend-verification-code',
    forgetPassword: base + 'ForgotPassword/Initiate',
    verifyForgetPassword: base + 'ForgotPassword/VerifyCode',
    resetPassword: base + 'ForgotPassword/ResetPassword',
    getExtraDrivers: base + "Drivers/GetExtraDrivers/",
    checkout: base + "PurchaseOrders/checkout",


    // Change Vehicle Images
    getFrontImage: base + "Vehicles/front-image/",
    uploadFrontImage: base + "Vehicles/upload-front-image/",
    getBackImage: base + "Vehicles/rear-image/",
    uploadBackImage: base + "Vehicles/upload-rear-image/", 
    getRightImage: base + "Vehicles/right-image/",
    uploadRightImage: base + "Vehicles/upload-right-image/",
    getLeftImage: base + "Vehicles/left-image/",
    uploadLeftImage: base + "Vehicles/upload-left-image/",
    getPlateNumberImage: base + "Vehicles/chassis-number-image/",
    uploadPlateNumberImage: base + "Vehicles/upload-chassis-number-image/",


    // Profile
    myProfileData: base + "Profile/Main",
    deleteDriver: base + "Profile/DeleteDriver/",
    voucherSammary: base+ "PurchaseOrders/payment-status/",
    getPolices: base + "Profile/Policies",
    deleteVehicle: base + "Profile/DeleteVehicle/",
    registerdIds : base + "Profile/RegisteredIds",
    profileVehicles : base + "Profile/Vehicles",

    PurchaseOrderSummary : base + "PurchaseOrders/summary/",
    PaymentStatus : base + "PurchaseOrders/payment-status/",
    GetPolicyDetails: base + "Policies/Get/",

    PrivacyPolicy: base + "Configuration/PrivacyPolicy",
    Terms: base + "Configuration/Terms",
    ConfigurationUrl: base + "Configuration",
    ContactUs: base + "ContactUs",

    FAQs: base + "Faqs/list",

    changePassword: base + "Accounts/change-password",
    updateUserInfo: base + "Accounts/update-info",
    getUserInfo: base + "Accounts/user-info",
    changeMobileCode: base + "Accounts/mobile/change/send-verification",
    changeMobileNumber: base + "Accounts/mobile/change/update",

    changeEmailCode: base + "Accounts/email/change/send-verification",
    changeEmail: base + "Accounts/email/change/update",


    // Notifications
    saveNotification: base + "Accounts/notifications/update",
    getNotification: base + "Accounts/notifications/get",

    CancelPolicy: base + "Policies/CancelPolicy"
}