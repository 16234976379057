<div class="container actions">
    <button (click)="navigationPageTo('login')" class="active"> {{ 'login' | translate }} </button>
    <button (click)="navigationPageTo('signUp')" > {{ 'signUp' | translate }} </button>
</div>
<section @fadeInUp class="auth-control">
    <div class="close cursor-pointer" (click)="closePopup()">
        {{ 'close' | translate }}
    </div>

    <div class="custom-container">
        <div class="auth-control-header">
            <img *ngIf="currentLanguage == 'ar'" [src]="baseHeaderImagePath + 'logoArBlack.svg'" width="150px" alt="">
            <img *ngIf="currentLanguage == 'en'" [src]="baseHeaderImagePath + 'logoEnBlack.svg'" width="150px" alt="">
        </div>
    </div>

    <div class="auth-control-body mt-3"  style="overflow-y: scroll; max-height: 450px;">
        <div class="custom-container">
            <p class="my-3 f-bolder"> {{ 'LogInToYourAccount' | translate }} </p>

            <div class="row" [formGroup]="form" >  

                <!-- idNumber -->
                <div class="col-md-12 my-1">
                    <mat-label> {{ 'idNumber' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input maxlength="10" autocomplete="off" matInput type="text" formControlName="IdNumber">
                        <mat-error>
                            <span *ngIf="form.get('IdNumber')?.value"> {{ 'errorIdNumber' | translate }} </span>
                            <span *ngIf="form.get('IdNumber')?.hasError('required')"> {{ 'thisFieldIsReuired' | translate }} </span>
                        </mat-error>
                    </mat-form-field>
                </div> 
 
                
                <!-- Password -->
                <div class="col-md-12 my-2">
                    <mat-label class="f-bolder"> {{ 'password' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input (keyup.enter)="login()"  type="password" matInput formControlName="password" [placeholder]=" 'enterYourPassword' | translate " >
                        <mat-error> {{ 'passwordIsRequired' | translate }} </mat-error>
                    </mat-form-field> 
                </div>
    
                <!-- CAPCHAA -->
                <div class="col-sm-12 capchaa-cont">
                    <mat-form-field appearance="outline">
                        <img style="display: flex; align-items: center; margin-top: 9px; height: 100%;" matPrefix [src]="imageCapcha">
                        <img style="display: flex; align-items: center; margin-top: 9px; height: 100%;" matSuffix [src]="'assets/images/_home/resenCodeIcon.svg'">
                        <input (keyup.enter)="login()" style="font-size: 20px !important; padding: 0 20px;"
                            autocomplete="off" matInput type="text" formControlName="CaptchaString">
                        <mat-error> {{ 'thisFieldIsReuired' | translate }} </mat-error>
                    </mat-form-field>
                </div>


                <!-- Submit Button -->
                <div class="col-md-12">
                    <button class="main-btn w-100"  (click)="login()" > {{ 'login' | translate }} </button>
                    <div class="text-center my-3 text-blue cursor-pointer" (click)="navigationPageTo('resetPassword')">
                        {{ 'forgetPassword' | translate }}
                    </div>
                    <div class="text-center my-3">
                        <span class="text-gray"> {{ 'dontHaveAnAccount' | translate }} </span> 
                        <span class="text-blue cursor-pointer" (click)="navigationPageTo('signUp')"> {{ 'registerYourAccount' | translate }} </span>
                    </div>  
                </div> 
            </div> 
        </div> 
    </div> 
</section> 
