import { FormTravelModule } from './pages/form-travel/form-travel.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink'; 
import { AuthGuard } from './__core/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/', 
    pathMatch: 'full',
  }, 
  {
    path: "",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) 
  },  
  {
    path: "form",
    loadChildren: () => import('./pages/form-car/form-root.module').then(m => m.FormRootModule)
  },  
  {
    path: "travel-form",
    loadChildren: () => import('./pages/form-travel/form-travel.module').then(m => m.FormTravelModule)
  },  
  {
    path: "medical-form",
    loadChildren: () => import('./pages/form-medical/form-medical.module').then(m => m.FormTravelModule)
  },  
  {
    path: "profile",
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },{
    path: "payment",
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: "policies",
    loadChildren: () => import('./pages/policies/policies.module').then(m => m.PoliciesModule),
    canActivate: [AuthGuard]
  },
  {
    path: "**",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  }
]; 
 
@NgModule({ 

  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  }), QuicklinkModule],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule { } 