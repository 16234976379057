import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoleDirective } from './__core/directive/role.directive';
import { CoreModule } from 'src/app/__core/core.module'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';    
import { SharedModule } from './__shared/shared.module';  
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'; 
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HeaderComponent } from './pages/__header/header.component';
import { LoaderComponent } from './__core/components/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    RoleDirective,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule, 
    MatProgressSpinnerModule,
    SharedModule
  ],  
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: "appearance"
    }, 
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Location, 
    DatePipe
  ],
  bootstrap: [AppComponent], 
 
})
export class AppModule { }

