import { Injectable } from '@angular/core';
import { English } from 'src/assets/i18n/en';
import { Arabic } from 'src/assets/i18n/ar';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  defaultLanguage: string = "ar";
  currentLanguage: string | null = "ar";
  labelsApp: any;
  direction: string = 'ltr;'

  constructor() { }  

  handleLanguage() {  
    this.currentLanguage = localStorage.getItem('language'); 

    if (this.currentLanguage) {
      this.currentLanguage == 'en' ? this.initiateLanguage('en') : this.initiateLanguage('ar')
    } else {
      localStorage.setItem('language', this.defaultLanguage);
      this.initiateLanguage(this.defaultLanguage); 
    }
  }

  public changeTo(language: string) {
    if(language != 'ar' && language != 'en') { return; }
    if(language == this.currentLanguage) {return;}
    this.defaultLanguage = language;
    localStorage.setItem('language', this.defaultLanguage);
    this.initiateLanguage(language);
  }

  initiateLanguage(language: string) {
    if (language === 'en') { 
      this.currentLanguage = 'en';
      document.dir = 'ltr'  
    } else {
      this.currentLanguage = 'ar'
      document.dir = 'rtl'
    }
    this.labelsApp = this.getLabelsApp(this.currentLanguage);
  }

  getLabelsApp(language: string) {
    return this.labelsApp = (language == 'en' ? English : Arabic);
  }

  getCurrentLanguage = (): string => localStorage.getItem('language') as string;

  translate = (label: string) => this.labelsApp[label] ? this.labelsApp[label] : label; 

}



