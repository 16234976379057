<ess-loader></ess-loader>
<section>
    <ess-login *ngIf="pageName == 'login'"
                (close)="close.emit(true)"
                (isLoggedIn)="checkUserLoggedIn($event)"
                (navigationTo)="pageName = ($event)"></ess-login>

    <ess-sign-up *ngIf="pageName == 'signUp'"
                (close)="close.emit(true)"
                 (navigationTo)="pageName = ($event)"></ess-sign-up>

    <ess-reset-password *ngIf="pageName == 'resetPassword'"
                (close)="close.emit(true)"
                 (navigationTo)="pageName = ($event)"></ess-reset-password>
</section> 