import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Inject, OnInit } from '@angular/core'; 
import { NavItem } from 'src/app/__core/interfaces/navigationItems';  
import { animate, state, style, transition, trigger } from '@angular/animations';  
import { Title } from '@angular/platform-browser';
import { environment as env, environment } from 'src/environments/environment';  
import { TranslationService } from './__core/services/translation.service';
import { SplashScreenService } from './__core/services/splash-screen.service';
import { AppService } from './__core/services/app.service';
import { SwiperOptions } from 'swiper'; 
import { SwiperOptionsConfig } from './__core/variables/SwiperOptions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)') 
      ),
    ])
  ], 
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit, AfterViewInit { 

  
  isScrolled = false; 
  isUserLoggedIn: boolean = this.appService.isUserLoggedIn();
  config: SwiperOptions = SwiperOptionsConfig;
  menus: any
  
  constructor(
    private title: Title,
    private translate: TranslationService,
    private splashScreenService: SplashScreenService,
    @Inject(DOCUMENT) private document: any, 
    public appService: AppService) { }

  ngOnInit(): void { 
    this.title.setTitle(env.appName)
    this.translate.handleLanguage();
  }

  ngAfterViewInit(): void {
  }
 
  userLoggedInItems: NavItem[] = [  
    {
      label: "favorite",
      icon: "person",
      route: "/", 
    },
    {
      label: "chats",
      icon: "person",
      route: "/", 
    },
    {
      label: "notifocations",
      icon: "person",
      route: "/", 
    },
    {
      label: "myProfile",
      icon: "person",
      route: "/profile", 
    },
    {
      label: "publishYourAd",
      icon: "person",
      route: "/app/advertisement-create", 
    },

  ]   
 
  userLoggedOutItems: NavItem[] = [  
    {
      label: "loginOrSignUp",
      icon: "person",
      route: "/auth/login-social-media",  
    },
  ]   

}
