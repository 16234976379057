import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/__core/services/app.service'; 
import { TranslationService } from 'src/app/__core/services/translation.service'; 
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ExperimentalLaunchDialogComponent } from 'src/app/__shared/components/experimental-launch-dialog/experimental-launch-dialog.component';

 
@Component({
  selector: 'ess-header', 
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('stagger', [
      transition('* => *', [
        query(':enter', stagger(60, animateChild()), { optional: true }),
      ])
    ]),
    trigger('fadeInUp', [
      transition(':enter', [
        style({
          transform: 'translateY(50px)',
          opacity: 0
        }),
        animate(`500ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateY(0)',
          opacity: 1
        }))
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0)',
          opacity: 1
        }),
        animate(`500ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateX(50px)',
          opacity: 0
        }))
      ]),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Input() NavigationBar: any;
  headerImgPath: string = "assets/images/_header/";
  iconsImgPath: string = "assets/images/_icons/"; 
  currentLanguage: any = this.lang.currentLanguage;
  isCloased: boolean = true;
  isUserLoggedIn: boolean = false;
 

  constructor(
    private lang: TranslationService, 
    public app: AppService,
    public dialog: MatDialog,
    public authService: AuthService,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cd.markForCheck();
    this.checkUserLoggedIn();
  }

  ngAfterViewInit(): void {
    this.cd.markForCheck();
    this.currentLanguage = this.lang.currentLanguage;


    let isExperimentalLaunchShowed = localStorage.getItem("isExperimentalLaunchShowed");
    if (!isExperimentalLaunchShowed) { 
      this.openExperimentalLaunchDialog()
    }
    
  }

  goToProfile = () => { }

  login = () => { }

  changeLanguage = () => {
    this.currentLanguage == 'en' ? localStorage.setItem('language', 'ar') : localStorage.setItem('language', 'en')
    location.reload();
  } 

  checkUserLoggedIn = () => {
    this.authService.isUserLoggedIn().subscribe(user => {
      this.isUserLoggedIn = user ? true : false; 
    });
  }
  
  openExperimentalLaunchDialog = () => { 
    this.dialog.open(ExperimentalLaunchDialogComponent, { width: '520px', height: '519px' });
    localStorage.setItem("isExperimentalLaunchShowed", 'true');
  }
  
}
