import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { interseptors } from './utils/interceptors'; 


@NgModule({
  declarations: [ 
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }), 
  ],
  providers: [...interseptors]
})
export class CoreModule { }

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
