import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { endPoints as api } from 'src/app/__api/endPoints'; 
import { baseResponse as res } from './../../__core/interfaces/baseResponse'; 
import { environment } from 'src/environments/environment';
import { User } from 'src/app/__core/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  
  public user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  
  constructor(private http: HttpClient) { }

  isUserLoggedIn = (): Observable<User | null> => { 
    let user: any = localStorage.getItem("UserData") as User | null; 
    if (user) { 
      this.user$.next(JSON.parse(user)); 
    }
    return this.user$.asObservable() 
  } 

  get getUser(): User | null { 
    return this.isUserLoggedIn() ? this.user$.value : null;
  }
  

  login = (req: FormData) => this.http.post<res<any>>(api.login, req);

  signUp = (req: FormData) => this.http.post<res<any>>(api.signUp, req);

  verifyRegister = (req: FormData) => this.http.post<res<any>>(environment.baseUrl + "Registration/VerifiyMobileNumber", req);  
  
  resendVerificationCode = (req: FormData) => this.http.post<res<any>>(environment.baseUrl + "Registration/ResendCode", req);
  
  forgetPassword = (req: FormData) => this.http.post<res<any>>(api.forgetPassword, req);
  
  verifyForgetPassword = (req: FormData) => this.http.post<res<any>>(api.verifyForgetPassword, req);

  resetPassword = (req: FormData) => this.http.post<res<any>>(api.resetPassword, req); 

  
  
}
