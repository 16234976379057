import { Component, EventEmitter, OnInit, Output } from '@angular/core'; 

@Component({
  selector: 'ess-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  @Output() close = new EventEmitter();  
  @Output() isLoggedIn = new EventEmitter();  
  pageName: any = "login"; 

  checkUserLoggedIn = (isUserLoggedIn: any) => { 
    if (isUserLoggedIn) this.isLoggedIn.emit(true); 
    else this.isLoggedIn.emit(false);
  }
  
}

