import { SwiperOptions } from "swiper";

export const SwiperOptionsConfig: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    
    spaceBetween: 30,
    autoplay: false,
    speed: 1000,
}; 

export const SwiperOptionsSlidesPerViewConfig: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: false,
    speed: 1000,
}; 